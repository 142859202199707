<template>
    <div class="modal fade show" id="IQMAssetWarningModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-modal="true" style="display: none">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title marginL5"><i class="fa fa-exclamation-triangle"></i><span class="marginL5">{{ $t("geo_notif_IQM_SERVER_notifications") }}</span></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>{{ textWarning }}</div>
                </div>
                <div class="modal-footer">
                    <button id="IQMAssetWarningModal_assetsButton" @click="onAssets" type="button" class="btn btn-brand kt-margin-r-5" data-dismiss="modal">{{ $t("asset_assets") }}</button>
                    <button id="IQMAssetWarningModal_cancelButton" type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t("common_cancel") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['textWarning'],
    methods: {
        onAssets() {
            this.$router.push({ name: "assets" });
        }
    }
};
</script>